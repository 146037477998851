import React, {useContext, useEffect, useState, useMemo, useCallback} from 'react';
import {getCalculatedValue} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import { gql } from "@apollo/client";
import useRequest from 'hooks/useRequest';
import ButtonPrimary from 'components/common/Button';
import Message from 'components/common/Message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faQuestionCircle, faSpider, faSpinner, faWarning } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';
import Tooltip from 'components/common/Tooltip';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import ParentContext from 'contexts/ParentContext';

const REQUEST = gql`
  mutation FileRequest($fileId: String!, $surveyId: String!, $request: String!, $requestVariables: JSONObject, $fresh: Boolean, $index: Int) {
    fileRequest(fileId: $fileId, surveyId: $surveyId, request: $request, requestVariables: $requestVariables, fresh: $fresh, index: $index) {
      variables,
      response,
      date
    }
  }
`;

const RequestInput = ({ 
  questionKey,
  request,
  tooltip,
  label,
  description,
  automatic,
  variables
}) => {
  const { file, updateFile, updateErrors } = useContext(SurveyContext);
  const [requestMutation, { loading, error, reset }] = useRequest({query: REQUEST});
  const [processing, setProcessing] = useState();
  const [cachedVariables, setCachedVariables] = useState({});
  const downloadRef = useRef(null);
  const [requiresLogin, setRequiresLogin] = useState();
  const [showConfirm, setShowConfirm] = useState();
  const [showMessage, setShowMessage] = useState();
  const [notice, setNotice] = useState();
  const {row, prevRows, parentPath} = useContext(ParentContext);
  const lastIndex = useMemo(() => (prevRows ? prevRows.at(-1) : undefined), [prevRows]);

  const requestVariables = useMemo(() => {
    return getCalculatedValue(file, variables, prevRows);
  }, [file, variables]);

  useEffect(() => {
    if (JSON.stringify(requestVariables) !== JSON.stringify(cachedVariables)) {
      setCachedVariables(requestVariables);
      reset();
    }
  }, [requestVariables, reset]);

  const notValid = useMemo(() => {
    if (!requestVariables) return true;
    for (let [key, value] of Object.entries(requestVariables)) {
      if (value === undefined) {
        return value
      }
    }
    if (!file.tokens.LTSAtoken) {
      setRequiresLogin(true);
      return "Login first with LTSA";
    }
    setRequiresLogin(false);
    return error ? error.message : false;
  },[error, requestVariables, file]);

  const tryRequest = useCallback((file, fresh, requestVariablesOverride) => {
      // do request
      setShowConfirm(false)
      let newRequest;
      if (lastIndex !== undefined) {
        newRequest = [...(Array.isArray(file.requests?.[request]) ? file.requests?.[request] : [])];
        newRequest[lastIndex] = undefined;
      }
      updateFile({requests: {...file.requests, [request]: newRequest}});
      requestMutation({variables: {fileId: file.id, surveyId: file.surveyId, request, requestVariables: requestVariablesOverride || requestVariables, fresh, index: lastIndex}})
      .then(res => {
        if (res.data.fileRequest.response.order && res.data.fileRequest.response.order.status === "Processing") {
          setProcessing(true);
          setTimeout(() => {
            tryRequest(file, false, res.data.fileRequest.variables);
          }, 1000);
        } else {
          setProcessing(false);
          if (res.data.fileRequest.response.path) {
            downloadRef.current.setAttribute('href', process.env.REACT_APP_API_URL + res.data.fileRequest.response.path);
            downloadRef.current.setAttribute('download', 'titlesearch.pdf');
            downloadRef.current.click();
            reset();
          } else {
            newRequest = res.data.fileRequest;
            if (lastIndex !== undefined) {
              newRequest = [...(Array.isArray(file.requests?.[request]) ? file.requests?.[request] : [])];
              newRequest[lastIndex] = res.data.fileRequest
            }
            updateFile({requests: {...file.requests, [request]: newRequest}});
          }
        }
      })
      .catch(res => {
        // TODO
        // check if error is due to token expiry
        if (res.errors ? res.errors[0].message && res.errors[0].message.includes("token") : res.message.includes("token")) {

          updateFile({requests: {...file.requests, tokens: {}}});
          setNotice("You must re-authenticate with LTSA")
          reset();
        }
      })
  }, [requestMutation, request, requestVariables, updateFile]);

  useEffect(() => {
    let requestPath = (lastIndex !== undefined) ? file.requests?.[request]?.[lastIndex] : file.requests?.[request]
    if (!loading && !processing && file.tokens && requestPath?.response?.order?.status === "Processing") {
      console.log("processing")
      tryRequest(file);
    }
  }, [tryRequest]);

  const outDated = useMemo(() => {
    if (file) {
      let requestPath = (lastIndex !== undefined) ? file.requests?.[request]?.[lastIndex] : file.requests?.[request]
      if (requestPath) {
        if (requestVariables && requestPath.variables && (JSON.stringify(requestVariables).replace('false', '""') == JSON.stringify(requestPath.variables).replace('false', '""'))) {
          return false;
        }
      }
      return true;
    }
  },[requestVariables, file, loading, request]);

  const required = useMemo(() => {
    if (file) {
      return !((lastIndex !== undefined) ? file.requests?.[request]?.[lastIndex] : file.requests?.[request]);
    }
  },[requestVariables, file, loading, request]);

  useEffect(() => {
    if (automatic) {
      let requestPath = (lastIndex !== undefined) ? file.requests?.[request]?.[lastIndex] : file.requests?.[request]
      if ((!file.tokens || !file.tokens.LTSAtoken) && (required || outDated) && requestPath) {
        // refresh request if (required || outDated) (dependencies changed)
        let newRequest = null;
        if (lastIndex !== undefined) {
          newRequest = [...(Array.isArray(file.requests?.[request]) ? file.requests?.[request] : [])];
          newRequest[lastIndex] = null
        }
        updateFile({requests: {...file.requests, [request]: newRequest}}, true);
      } else {
        if (!notValid && (required || outDated) && !loading  && !processing) {
          tryRequest(file);
        }
      }
    }
  }, [request, file, requestVariables, loading, notValid, tryRequest, required, outDated, automatic, updateFile]);

  return (
  automatic ?
    (
      notValid && (required || outDated) ? 
        <Message negative>{error ? error.message : `${notValid}`}</Message>
      :
      <></>
    )
    :
    <div className="flex flex-col gap-2 items-start">
      {showConfirm && 
        <ConfirmNotice 
          title="Update Request?"
          message={
            <div>
              Are you sure you want to refetch this information?
              <br></br>
              <small>{description}</small>
            </div>
          }
          onClose={() => setShowConfirm(false)}
          onConfirm={() => tryRequest(file, true)}
        />
      }
      {notice && 
        <ConfirmNotice 
          title="Authentication required"
          message={notice}
          onClose={() => setNotice()}
          onConfirm={() => setNotice()}
        />
      }
      {description && <Message positive>{description}</Message>}
      {notValid && (required || outDated) && !requiresLogin && <Message>{notValid} </Message>}
      <div onMouseEnter={() => setShowMessage(true)} onMouseLeave={() => setShowMessage(false)}>
        <ButtonPrimary className="relative" onClick={required ? () => tryRequest(file) : () => setShowConfirm(true)} disabled={loading || notValid}>
          {loading || processing ?
            <FontAwesomeIcon className="animate-spin" icon={faSpinner} /> 
            :
            label
          }
        </ButtonPrimary>
        {
          requiresLogin &&          
          <Tooltip 
            showOverride={showMessage}
            icon={faCircleExclamation} 
            color="orange" 
            message="You must login with your LTSA credentials first"
          />  
        }
        {
          (!requiresLogin && tooltip) &&          
          <Tooltip 
            icon={faQuestionCircle} 
            message={tooltip}
          />  
        }
      </div>
      <a className="absolute" ref={downloadRef} target="_blank"></a>
    </div>
  )
};

export default RequestInput;