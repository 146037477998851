import React, { useEffect, useRef } from 'react';
import { PaddedPage } from 'components/common/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faFaceSmile } from '@fortawesome/free-regular-svg-icons';



const Testimonials = () => {
  const itemsRef = useRef([]);
  const positionRef = useRef(0);

  useEffect(() => {
    let shift = () => {
      itemsRef.current[positionRef.current % itemsRef.current.length].style.transform = "scale(0.5)";
      itemsRef.current[positionRef.current % itemsRef.current.length].style.opacity = "50%";
      itemsRef.current[positionRef.current % itemsRef.current.length].style.left = "0";
      itemsRef.current[positionRef.current % itemsRef.current.length].style['z-index'] = "1";

      itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style.transform = "scale(1) translate(-50%)";
      itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style.opacity = "100%";
      itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style.left = "50%";
      itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style['z-index'] = "10";

      itemsRef.current[((positionRef.current + 2) % itemsRef.current.length)].style.transform = "scale(0.5) translate(-200%)";
      itemsRef.current[((positionRef.current + 2) % itemsRef.current.length)].style.opacity = "50%";
      itemsRef.current[((positionRef.current + 2) % itemsRef.current.length)].style.left = "100%";
      itemsRef.current[((positionRef.current + 2) % itemsRef.current.length)].style['z-index'] = "1";

      itemsRef.current[((positionRef.current + 3) % itemsRef.current.length)].style.transform = "scale(0.5) translate(200%, -50%)";
      itemsRef.current[((positionRef.current + 3) % itemsRef.current.length)].style.opacity = "20%";
      itemsRef.current[((positionRef.current + 3) % itemsRef.current.length)].style.left = "-0%";
      itemsRef.current[((positionRef.current + 3) % itemsRef.current.length)].style['z-index'] = "1";

      itemsRef.current[((positionRef.current + 4) % itemsRef.current.length)].style.transform = "scale(0.5) translate(200%, -50%)";
      itemsRef.current[((positionRef.current + 4) % itemsRef.current.length)].style.opacity = "20%";
      itemsRef.current[((positionRef.current + 4) % itemsRef.current.length)].style.left = "-0%";
      itemsRef.current[((positionRef.current + 4) % itemsRef.current.length)].style['z-index'] = "1";
      positionRef.current ++;
    }

    shift();
    let interval = setInterval(() => {
      shift();
    }, 8000);

    return () => {
      clearInterval(interval);
    }

  }, [itemsRef]);

  return (
    <div className="w-full bg-white">
      <PaddedPage className="self-center bg-white w-full">
        <div className="flex flex-col items-center pt-12 gap-8 text-center">
          <div className='flex flex-col gap-8'>
            <h1 className="text-primary">What Our Clients Say</h1>
            <h2 className="text-noromal text-lg">Focussing on good old fashioned customer service allows us to listen, customize documents, and build out the features that our clients need.</h2>
          </div>
          <div className="flex gap-2 justify-around items-end relative w-full"> 
            <div ref={el => itemsRef.current[0] = el} className="flex items-center flex-col gap-2 w-1/3 text-left absolute transition-all duration-1000 bottom-0">
              <div className="flex gap-8 flex-col items-center">
                <div className='bg-cream p-8 flex flex-col items-center rounded w-3/2 relative'>
                  <div className="flex gap-1 text-xl text-yellow">
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                  </div>
                  <p className="mt-2 text-center leading-5">
                    "ConveyMe interface is a testament to thoughtful design. It’s evident that the program was crafted by a team with a deep understanding of daily conveyance files and a keen awareness of what makes the process run smoothly. ConveyMe’s customer service is unparalleled. My experience with ConveyMe was nothing short of exceptional. I highly recommend ConveyMe to anyone in need of reliable and efficient conveyance program."
                  </p>
                  <div style={{ transform: 'rotate(45deg) translateX(-70%)', left: '50%'}} className={`absolute -bottom-8 w-8 h-8 border-cream border-r border-b bg-cream`}></div>
                </div>
                <div className="flex flex-col gap-2 items-center">
                  <div className="rounded-full p-2 bg-cream">
                    <FontAwesomeIcon className='text-6xl text-primary' icon={faFaceSmile}/>
                  </div>
                  <div className="flex flex-col items-center">
                    <h2 className="font-bold text-primary">Sherry</h2>
                    <h3 className="font-med text-med-grey">SF Notary Public</h3>
                  </div>
                </div>
              </div>
            </div>
            <div ref={el => itemsRef.current[1] = el} className="flex items-center flex-col gap-2 w-1/3 text-left absolute transition-all duration-1000 bottom-0">
              <div className="flex gap-8 flex-col items-center">
                <div className='bg-cream p-8 flex flex-col items-center rounded w-3/2 relative'>
                  <div className="flex gap-1 text-xl text-yellow">
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                  </div>
                  <p className="mt-2 text-center leading-5">
                    "I was surprised by the ease of the software. I was also super happy with the function to print checks. The price is reasonable. With a couple days, I was able to work on the first file. I only had the time to train my staff one week after. Now (2 weeks from the first Demo) all my staff are able to use the Conveyme to do their conveyances. The Conveyme staff are readily available and they listen. They are making improvement with the input from the users."
                  </p>
                  <div style={{ transform: 'rotate(45deg) translateX(-70%)', left: '50%'}} className={`absolute -bottom-8 w-8 h-8 border-cream border-r border-b bg-cream`}></div>
                </div>
                <div className="flex flex-col gap-2 items-center">
                  <div className="rounded-full p-2 bg-cream">
                    <FontAwesomeIcon className='text-6xl text-primary' icon={faFaceSmile}/>
                  </div>
                  <div className="flex flex-col items-center">
                    <h2 className="font-bold text-primary">Jason</h2>
                    <h3 className="font-med text-med-grey">First Class Notary</h3>
                  </div>
                </div>
              </div>
            </div>
            <div ref={el => itemsRef.current[2] = el}  className="flex items-center flex-col gap-2 w-1/3 text-left w-1/3 absolute transition-all duration-1000 bottom-0">
              <div className="flex gap-8 flex-col items-center">
                <div className='bg-cream p-8 flex flex-col items-center rounded w-full relative'>
                  <div className="flex gap-1 text-xl text-yellow">
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                  </div>
                  <p className="mt-2 text-center leading-5">
                    "What I love most about your system, not just the support but also how proactive you are on continually working with us and adding to create what I think will be the best conveyancing system."
                  </p>
                  <div style={{ transform: 'rotate(45deg) translateX(-70%)', left: '50%'}} className={`absolute -bottom-8 w-8 h-8 border-cream border-r border-b bg-cream`}></div>
                </div>
                <div className="flex flex-col gap-2 items-center">
                  <div className="rounded-full p-2 bg-cream">
                    <FontAwesomeIcon className='text-6xl text-primary' icon={faFaceSmile}/>
                  </div>
                  <div className="flex flex-col items-center">
                    <h2 className="font-bold text-primary">Noreen</h2>
                    <h3 className="font-med text-med-grey">Bryshun Mace Lawyers</h3>
                  </div>
                </div>
              </div>
            </div>
            <div ref={el => itemsRef.current[3] = el}  className="flex items-center flex-col gap-2 w-1/3 text-left w-1/3 absolute transition-all duration-1000 bottom-0">
              <div className="flex gap-8 flex-col items-center">
                <div className='bg-cream p-8 flex flex-col items-center rounded w-full relative'>
                  <div className="flex gap-1 text-xl text-yellow">
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                  </div>
                  <p className="mt-2 text-center leading-5">
                    "ConveyMe's intuitive platform and dedicated, amazing support team have been invaluable to our real estate practice. Their expertise in customizing our documents and their commitment to providing thorough training have allowed us to quickly and confidently integrate ConveyMe into our workflow."                  </p>
                  <div style={{ transform: 'rotate(45deg) translateX(-70%)', left: '50%'}} className={`absolute -bottom-8 w-8 h-8 border-cream border-r border-b bg-cream`}></div>
                </div>
                <div className="flex flex-col gap-2 items-center">
                  <div className="rounded-full p-2 bg-cream">
                    <FontAwesomeIcon className='text-6xl text-primary' icon={faFaceSmile}/>
                  </div>
                  <div className="flex flex-col items-center">
                    <h2 className="font-bold text-primary">Melissa</h2>
                    <h3 className="font-med text-med-grey">Northam Law Corporation</h3>
                  </div>
                </div>
              </div>
            </div>
            <div ref={el => itemsRef.current[4] = el}  className="flex items-center flex-col gap-2 w-1/3 text-left w-1/3 absolute transition-all duration-1000 bottom-0">
              <div className="flex gap-8 flex-col items-center">
                <div className='bg-cream p-8 flex flex-col items-center rounded w-full relative'>
                  <div className="flex gap-1 text-xl text-yellow">
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                  </div>
                  <p className="mt-2 text-center leading-5">
                    "Staff are friendly and exceptionally professional, always willing to help without hesitation."</p>
                  <div style={{ transform: 'rotate(45deg) translateX(-70%)', left: '50%'}} className={`absolute -bottom-8 w-8 h-8 border-cream border-r border-b bg-cream`}></div>
                </div>
                <div className="flex flex-col gap-2 items-center">
                  <div className="rounded-full p-2 bg-cream">
                    <FontAwesomeIcon className='text-6xl text-primary' icon={faFaceSmile}/>
                  </div>
                  <div className="flex flex-col items-center">
                    {/* <h2 className="font-bold text-primary">Melissa</h2> */}
                    <h3 className="font-med text-med-grey">Wendy Tang Notary Public</h3>
                  </div>
                </div>
              </div>
            </div>
            {/* placeholder */}
            <div className="flex items-center flex-col gap-2 w-1/3 text-left invisible">
              <div className="flex gap-8 flex-col items-center">
                <div className='bg-cream p-8 flex flex-col items-center rounded w-3/2 relative'>
                  <div className="flex gap-1 text-xl text-yellow">
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                    <FontAwesomeIcon icon={faStar}/>
                  </div>
                  <p className="mt-2 text-center leading-5">
                    "I was surprised by the ease of the software. I was also super happy with the function to print checks. The price is reasonable. With a couple days, I was able to work on the first file. I only had the time to train my staff one week after. Now (2 weeks from the first Demo) all my staff are able to use the Conveyme to do their conveyances. The Conveyme staff are readily available and they listen. They are making improvement with the input from the users."
                  </p>
                  <div style={{ transform: 'rotate(45deg) translateX(-70%)', left: '50%'}} className={`absolute -bottom-8 w-8 h-8 border-cream border-r border-b bg-cream`}></div>
                </div>
                <div className="flex flex-col gap-2 items-center">
                  <div className="rounded-full p-2 bg-cream">
                    <FontAwesomeIcon className='text-6xl text-primary' icon={faFaceSmile}/>
                  </div>
                  <div className="flex flex-col items-center">
                    <h2 className="font-bold text-primary">Jason</h2>
                    <h3 className="font-med text-med-grey">First Class Notary</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PaddedPage>
    </div>
  );
};

export default Testimonials;
