import React, {useState, useEffect} from 'react'

export default function List({options, initialValue, onChange, className, valueOverride, nullable, readOnly}) {
  const [value, setValue] = useState(initialValue || '');

  const changeValue = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(newValue);
  }

  useEffect(() => {
    if (valueOverride || (valueOverride === '' && value)) {
      setValue(valueOverride);
    }
  }, [valueOverride]);

  return (
    <select 
      className={`
        border-2 p-1
        shadow-md
        ${className}
        ${readOnly ? 'bg-grey' : ''}
      `} 
      value={value} 
      onChange={changeValue}
      disabled={readOnly}
    >
      {options.map((option) => (
        <option
        key={option.value + option.name}
        name={option.value.toString()} 
        value={option.value}
        >
          {option.name}
        </option>
      ))
      }
      <option className={`${nullable ? '' : 'hidden'}`} value={null}/>
    </select>
  )
}
