import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimary from 'components/common/Button';
import { FullWidthPage, PaddedPage } from 'components/common/Layout'
import Input, { List, Radios } from 'components/inputs';
import ActivePageContext from 'contexts/ActivePageContext';
import useFirmChoices from 'hooks/useFirmChoices';
import useTrustAccountChoices from 'hooks/useTrustAccountChoices';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import _ from "lodash";

let startAndEndOfMonth = () => {
  const date = new Date();
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  const month = date.getMonth() + 1;
  const monthFormat = `${date.getFullYear()}-${month > 9 ? month : '0' + month}`
  return [monthFormat + `-01`, monthFormat + `-${lastDay}`];
}

export default function TrustAccountNav({children}) {
  const [from, setFrom] = useState(startAndEndOfMonth()[0]);
  const [to, setTo] = useState(startAndEndOfMonth()[1]);
  const [exportCSV, setExportCSV] = useState();
  const {firmChoices, firmId, setFirmId} = useFirmChoices(true, false, false, false, false, true);
  const [filters, setFilters] = useState({});
  const {trustAccountChoices, trustAccountId, setTrustAccountId} = useTrustAccountChoices(true, firmId);
  const [activePage, setActivePage] = useState("");
  const [pushedFilters, setPushedFilters] = useState({firmId, from, to, filters, trustAccountId});

  useEffect(() => {
    if (!pushedFilters?.trustAccountId && trustAccountId) {
      setPushedFilters({...pushedFilters, trustAccountId});
    }
  }, [trustAccountId]);

  const updateFilters = () => {
    setPushedFilters({firmId, from, to, filters, trustAccountId});
  }

  return (
    <FullWidthPage top bottom className="grow">
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <Link className={`pb-3 no-underline ${activePage === '' ? 'border-b border-dark-grey' : 'text-med-grey'}`} to={`/trust-account`}>
          Trust Account
          </Link>
          <Link className={`pb-3 no-underline ${activePage === 'chequesCleared' ? 'border-b border-dark-grey' : 'text-med-grey'}`} to={`/trust-account/cheques-cleared`}>
          Cheques Cleared
          </Link>
          <Link className={`pb-3 no-underline ${activePage === 'outstandingCheques' ? 'border-b border-dark-grey' : 'text-med-grey'}`} to={`/trust-account/outstanding-cheques`}>
          Outstanding Cheques
          </Link>
          <Link className={`pb-3 no-underline ${activePage === 'taf' ? 'border-b border-dark-grey' : 'text-med-grey'}`} to={`/trust-account/taf`}>
          TAF Report
          </Link>
          <Link className={`pb-3 no-underline ${activePage === 'list' ? 'border-b border-dark-grey' : 'text-med-grey'}`} to={`/trust-account/list`}>
          Trust Account List
          </Link>
          <Link className={`pb-3 no-underline ${activePage === 'liability' ? 'border-b border-dark-grey' : 'text-med-grey'}`} to={`/trust-account/liability`}>
          Trust Liability
          </Link>
          <Link className={`pb-3 no-underline ${activePage === 'reconciliation' ? 'border-b border-dark-grey' : 'text-med-grey'}`} to={`/trust-account/reconciliation`}>
          Final Reconciliation
          </Link>
          <Link className={`pb-3 no-underline ${activePage === 'gstpst-report' ? 'border-b border-dark-grey' : 'text-med-grey'}`} to={`/trust-account/gstpst-report`}>
          GST PST Report
          </Link>
        </div>
        <div className="flex justify-between border rounded p-2 border-med-grey items-center">
          <div className="flex gap-2 items-center">
            <div className="flex flex-col">
              <label className="text-sm">Firm</label>
              <List valueOverride={firmId} onChange={setFirmId} className="text-xs py-0.5" options={[...firmChoices, {name: "Mine", value: ""}]}/>
            </div>
            {
              (!activePage || ['reconciliation', 'outstandingCheques', 'chequesCleared', 'list'].includes(activePage)) && trustAccountChoices.length >= 1 && 
              <div className="flex flex-col">
                <label className="text-sm">Trust Account</label>
                <List valueOverride={trustAccountId} onChange={setTrustAccountId} className="text-xs py-0.5" options={trustAccountChoices}/>
              </div>
            }
            <div className="flex flex-col">
              <label className="text-sm">Month</label>
              <div className="flex gap-2 items-center">
                <input type="date" value={from} onChange={(e) => setFrom(e.target.value)}/>
                <FontAwesomeIcon icon={faArrowRight}/>
                <input type="date" value={to} onChange={(e) => setTo(e.target.value)}/>
              </div>
            </div>
            {
              !activePage && 
              <>
                <div className="flex flex-col">
                  <label className="text-sm">Filter</label>
                  <Radios inline options={[{name: "All", value: ""}, {name: "Cleared", value: true}, {name: "Uncleared", value: false}]} valueOverride={filters.cleared} onChange={(cleared) => setFilters({...filters, cleared})} />
                </div>
                <div className="flex flex-col">
                  <label className="text-sm">Type</label>
                  <List
                    className="bg-transparent"
                    onChange={(type) => setFilters({...filters, type})}
                    options={[
                      {name: "All", value: ''},
                      {name: "Bank Draft", value: 'bank draft'},
                      {name: "Cheque", value: 'cheque'},
                      {name: "EFT", value: 'eft'},
                      {name: "Account Transfer", value: 'transfers'},
                      {name: "File Transfer", value: 'file transfer'},
                      {name: "Float", value: 'float'},
                    ]}
                    initialValue={filters.type}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-sm">In/Out</label>
                  <Radios inline options={[{name: "All", value: ""}, {name: "In", value: 'in'}, {name: "Out", value: 'out'}]} valueOverride={filters.inOut} onChange={(inOut) => setFilters({...filters, inOut})} />
                </div>
              </>
            }
            {
              !activePage && 
              <div className="flex flex-col">
                <label className="text-sm">Search by Cheque #</label>
                <Input valueOverride={filters.search} onBlur={(search) => setFilters({...filters, search})}/>
              </div>
            }
            <ButtonPrimary onClick={updateFilters} className="justify-self-end mt-5 text-sm bg-green text-white border-med-grey`">Update</ButtonPrimary>
          </div>
          {(!activePage || ['taf', 'liability', 'gstpst-report'].includes(activePage)) && exportCSV && <ButtonPrimary onClick={() => exportCSV.call()} className="justify-self-end text-sm border-med-grey`">Export to CSV</ButtonPrimary>}
        </div>
        <div>
          <ActivePageContext.Provider value={{...pushedFilters, setExportCSV, setActivePage, trustAccountChoices}}>
            {children}
          </ActivePageContext.Provider>
        </div>
      </div>
    </FullWidthPage>
  )
}
