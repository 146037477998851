import { gql, useMutation, useQuery } from '@apollo/client';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullWidthPage } from 'components/common/Layout';
import Input, { Checkbox, List } from 'components/inputs';
import SurveyContext from 'contexts/SurveyContext';
import useExportCSV from 'hooks/useExportCSV';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { formatCurrency } from 'selectors/formSelectors';
import TrustAccountInput from './TrustAccountInput';
import ActivePageContext from 'contexts/ActivePageContext';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import DocumentGroup from 'components/documents/DocumentGroup';

// get trust account items for this file
const GET_TRUST_ACCOUNT_ITEMS = gql`
  query GetTrustAccountItems($filters: JSONObject, $firmId: String, $fileId: String, $trustAccountId: String) {
    trustAccountItems(filters: $filters, firmId: $firmId, fileId: $fileId, trustAccountId: $trustAccountId) {
      id,
      firmId,
      name,
      value,
      calculation,
      date,
      chequeDepositNumber,
      surveyId,
      payee,
      fileId,
      trustAccountId,
      type,
      cleared,
      void,
      payee,
      file {
        fileNumber
      }
    }
  }
`;

const GET_DOCUMENTS = gql`
  query GetDocuments($firmId: String, $type: String, $filters: JSONObject) {
    documents(firmId: $firmId, type: $type, filters: $filters) {
      id,
      name,
      category,
      firmId,
      type
    }
  }
`;

const GET_TRUST_ACCOUNT = gql`
  query GetTrustAccount($id: String) {
    trustAccount(id: $id) {
      id,
      name,
      transitNumber,
      accountNumber,
      institutionNumber,
      chequeNumber,
      firmId,
      bank {
        id,
        companyName,
        email,
        phoneNumber,
        fax,
        type,
        addressLineOne,
        addressLineTwo,
        city,
        province,
        postalCode,
        incorporationNumber,
        gstNumber,
      }
    }
  }
`;

export default function OutstandingCheques() {
  const {setActivePage, firmId, from, to, trustAccountId, setExportCSV} = useContext(ActivePageContext);
  setActivePage("outstandingCheques")
  const {exportToCsv} = useExportCSV();
  const { loading, error, data, refetch } = useQuery(GET_TRUST_ACCOUNT_ITEMS, {
    variables: {firmId, trustAccountId, filters: {to: to, cleared: false, void: null}},
    fetchPolicy:'no-cache'
  });
  const [startingBalance, setStartingBalance] = useState(0);
  const { loading:loadingDocs, error:errorDocs, data:dataDocs } = useQuery(GET_DOCUMENTS, {
    variables: {firmId, type: "trust", filters: {search: "Outstanding"}},
    fetchPolicy:'no-cache'
  });
  const { loading:loadingTrust, error:errorTrust, data:dataTrust } = useQuery(GET_TRUST_ACCOUNT, {
    variables: {id: trustAccountId},
    skip: !trustAccountId,
    fetchPolicy:'no-cache'
  });

  const items = useMemo(() => {
    return data?.trustAccountItems.filter(item => item.value < 0);
  }, [data?.trustAccountItems]);

  const totals = useMemo(() => {
    return items?.reduce((prev, current) => {
      if (current.value > 0) {
        prev.in += Number(current.value);
        prev.balances.push((prev.balances.at(-1) || 0) + Number(current.value))
      } else {
        prev.out += Number(current.value) * -1;
        prev.balances.push((prev.balances.at(-1) || 0) + Number(current.value))
      }
      return prev;
    }, {in: 0, out: 0, balances: [Number(startingBalance)]}) || {in: 0, out: 0, balances: []};
  }, [data, startingBalance])

  useEffect(() => {
    if (!setExportCSV) return;
    if (items?.length > 0) {
      setExportCSV({
        call: () => exportToCsv(items.map((item) => ({fileMatter: item.file?.fileNumber, name: item.name, type: item.type, depositNumber: item.chequeDepositNumber, amount: formatCurrency(Math.abs(item.value))})), `Outstanding Cheques Report ${new Date()}`)
      })
    } else {
      setExportCSV();
    }
  }, [data]);

  return (
    <div className="flex flex-col gap-4 pb-12">
      <div className="bg-light-grey rounded p-2">
        <table className="w-full bg-light-grey border-spacing-2">
          <tbody>
            <tr className="border-b border-dark-grey">
              <th>File Matter</th>
              <th>Description</th>
              <th>Payee/Payor</th>
              <th>Cheque/Deposit Number</th>
              <th>Print Date</th>
              <th>Amount</th>
              <th></th>
            </tr>
            <tr className="border-t border-med-grey">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            {items?.map((item, i) => (
              <tr key={i} className={`group relative ${i%2 === 0 ? 'bg-transparent-grey' : ''}`}>
                <td className="p-1"><Link to={`/files/${item.surveyId}/${item.fileId}/trust-account`}>{item.file?.fileNumber}</Link></td>
                <td className="p-1">{item.name}</td>
                <td className="p-1 text-center">{item.payee}</td>
                <td className="p-1 text-center"><Input disabled={true} className="w-20 text-center bg-transparent shadow-none" valueOverride={item.chequeDepositNumber || ''}/></td>
                <td className="p-1 text-center"><Input disabled={true} className="bg-transparent" type="date" valueOverride={new Date(item.date).toISOString().split('T')[0]}/></td>
                <td className={`text-right p-1`}><Input disabled={true} type="number" className={`bg-transparent w-32 text-right ${item.void ? 'line-through' : ''}`} valueOverride={item.value < 0 ?  formatCurrency(Math.abs(item.value)) : 0} /></td>
                {/* <td className={`text-right p-1 ${item.void ? 'line-through' : ''}`}>{item.value > 0 ? formatCurrency(item.value) : ''}</td>
                <td className={`text-right p-1 ${item.void ? 'line-through' : ''}`}>{item.value < 0 ? formatCurrency(item.value * -1) : ''}</td> */}
              </tr>
            ))}
            <tr className="border-t border-med-grey">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="text-right pt-2">{formatCurrency(totals.out)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {dataDocs && 
        <div className="relative">
          <DocumentGroup
            // zip={zip} 
            title="Trust Documents" 
            documents={dataDocs?.documents}
            extraData={{
              trustAccount: dataTrust?.trustAccount,
              trustAccountItemsOut: items,
              to: to,
              totals: totals
            }}
          />
        </div>
      }
    </div>
  )
}
