import React, { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client';
import Input from 'components/inputs';

const GET_TRUST_ACCOUNT_BALANCE = gql`
  query GetTrustAccountBalance($filters: JSONObject, $trustAccountId: String!) {
    trustAccountBalance(filters: $filters, trustAccountId: $trustAccountId)
  }
`;

const CREATE_TRUST_ACCOUNT_BALANCE = gql`
  mutation CreateTrustAccountBalance($value: String, $trustAccountId: String!, $date: String!) {
    createTrustAccountBalance(value: $value, trustAccountId: $trustAccountId, date: $date) {
      id,
      date,
      value,
    }
  }
`;

export default function TrustAccountBalanceInput({trustAccountId, date, onChange}) {
  const [createTrustAccountBalanceMutation, { loading:createLoading }] = useMutation(CREATE_TRUST_ACCOUNT_BALANCE);
  const [balance, setBalance] = useState(0);
  const { data: trustAccountBalance} = useQuery(GET_TRUST_ACCOUNT_BALANCE, {
    variables:{trustAccountId, filters: {to: date}},
    fetchPolicy:'no-cache',
    skip: !trustAccountId,
    onCompleted(res) {
      onChange(res.trustAccountBalance);
      setBalance(res.trustAccountBalance);
    }
  });

  const createTrustAccountBalance = (value) => {
    if (value != trustAccountBalance?.trustAccountBalance || (value === undefined) || (value === '')) {
      createTrustAccountBalanceMutation({variables: {value: value.toString(), trustAccountId, date}})
      .then((res) => {
        setBalance(res.data.createTrustAccountBalance.value);
        onChange(res.data.createTrustAccountBalance.value)
      })
      .catch((e) => {
        console.log(e);
      })
    }
  }

  return (
    <Input className="bg-transparent text-right w-28" type="number" valueOverride={balance || trustAccountBalance?.trustAccountBalance || 0} strictBlur onBlur={createTrustAccountBalance}/>
  )
}
