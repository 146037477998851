import React from 'react';

// TODO change name why is this called total
export default function Total({children, hidden, formStyles}) {
  return (
    <div className={`relative flex flex-col total ${hidden && 'hidden absolute'} ${formStyles}`}>
        {children}
    </div>
  )
}
